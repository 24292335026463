
import { defineComponent, reactive, toRefs, ref } from "vue";
import pagaTable from "@/components/pagination-table/index";
import formSearch from "@/components/page-search/form";
import popupWindow from "./popupNew.vue";
import { message } from "ant-design-vue";
import {
  UserAddressController_page,
  UserAddressController_del,
} from "@/api/mallManagement/placeOfShipmentManagement";
const purchaseColumns = [
  {
    title: "地址",
    slots: { customRender: "address" },
    width: "200px",
  },
  {
    title: "详细地址",
    dataIndex: "detailInfo",
  },
  {
    title: "电话号码",
    dataIndex: "telNum",
  },
  {
    title: "操作",
    dataIndex: "operation",
    slots: { customRender: "operation" },
  },
];
export default defineComponent({
  name: "questionType",
  components: {
    pagaTable,
    popupWindow,
    formSearch,
  },
  setup(props: any) {
    const data = reactive({
      purchaseColumns,
      selectedRowKeys: [],
      extraModel: {},
      formBtn: {
        colProps: { span: 16 },
        formItemProps: {
          wrapperCol: {
            span: 24,
          },
        },
      },
    });
    const table = ref();
    const getDataApi = (params: any) => {
      return new Promise((reslove) => {
        const params_api = {
          currentPage: params.pageIndex,
          pageSize: params.pageSize,
        };
        UserAddressController_page(params_api).then((res: any) => {
          reslove(res);
        });
      });
    };
    const handleChange = (
      pagination: any,
      filter: any,
      sorter: any,
      { currentDataSource }: any
    ) => {
      console.log(pagination, filter, sorter, currentDataSource);
    };
    const handleClick = (text: string, row: any) => {
      Title.value = "编辑";
      popupWindow.value.showModal(row);
    };
    const formSearch = ref();
    const popupWindow = ref();
    const Title = ref();
    const handleAdd = () => {
      Title.value = "新增";
      popupWindow.value.showModal();
    };
    const handleSearch = (formState: any) => {
      table.value.refresh();
    };
    const refresh = () => {
      table.value.refresh();
      data.selectedRowKeys = [];
    };

    const del = (val: any) => {
      UserAddressController_del({ id: val.id }).then((res) => {
        table.value.refresh();
        message.success("操作成功");
      });
    };
    const roleId = ref();
    return {
      del,
      refresh,
      ...toRefs(data),
      table,
      getDataApi,
      handleChange,
      handleClick,
      formSearch,
      popupWindow,
      Title,
      handleAdd,
      handleSearch,
    };
  },
});
